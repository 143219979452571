import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import SimplexNoise from 'simplex-noise';
//import { KawaseBlurFilter } from '@pixi/filter-kawase-blur';
import hsl from 'hsl-to-hex';
import { ReplaySubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';


let themeColorPalette = {
  'blue': [hsl(191, 41, 55), hsl(170, 39, 57), hsl(198, 46, 55), hsl(217, 67, 56)],
  'green': [hsl(191, 41, 55), hsl(171, 39, 56), hsl(198, 46, 55), hsl(217, 67, 56)],
  'pink': [hsl(148, 100, 51), hsl(148, 83, 61), hsl(148, 70, 71), hsl(147, 60, 80), hsl(147, 56, 85)],
  'purple': [hsl(280, 44, 55), hsl(217, 67, 56)],
  'yellow': [hsl(170, 39, 57), hsl(43, 91, 64)],
  'menu': [hsl(170, 39, 57), hsl(191, 41, 55), hsl(170, 39, 57), hsl(138, 31, 46)],
  'loader': [hsl(280, 44, 55), hsl(217, 67, 56), hsl(170, 39, 57), hsl(198, 46, 55),
  hsl(191, 41, 55), hsl(171, 39, 56), hsl(217, 67, 56),
  hsl(148, 100, 51), hsl(148, 83, 61), hsl(148, 70, 71), hsl(147, 60, 80), hsl(147, 56, 85),
  hsl(43, 91, 64)
  ]


}

@Directive({
  selector: '[appGradientBackground]'
})
export class GradientBackgroundDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input() gradientPalette: '1' | '5' | '9' | '3' | 'menu' | 'loader';
  @Input() showGradient: boolean = true;
  @Input() gradientHolder: boolean = false;
  @Input() autoPlay: boolean = false;

  @Input() isFullBg: boolean = false;

  public orbs = [];
  public app;
  public playGradient: ReplaySubject<boolean> = new ReplaySubject();
  public graphics;

  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  constructor(public elementRef: ElementRef, private ngZone: NgZone, @Inject(PLATFORM_ID) private platformId: Object) { }

  // return a random number within a range
  public random(min, max) {
    return Math.random() * (max - min) + min;
  }

  // map a number from 1 range to another
  public map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.isBrowser) {

      import('pixi.js-legacy').then(PIXI => {


        if (this.showGradient) {
          this.ngZone.runOutsideAngular(() => {

            let canvas = document.createElement('canvas');

            if (this.gradientHolder) {
              this.elementRef.nativeElement.appendChild(canvas);
            }
            else {
              if (this.elementRef.nativeElement.querySelector('.canvas-container')) {
                this.elementRef.nativeElement.querySelector('.canvas-container').appendChild(canvas)
              }
            }
            // Create a new simplex noise instance
            // Create PixiJS app
            this.app = new PIXI.Application({
              // render to <canvas class="orb-canvas"></canvas>
              view: this.elementRef.nativeElement.getElementsByTagName('canvas')[0],
              //  sharedTicker: true,
              // auto adjust size to fit the current window
              //resizeTo: window,
              // transparent background, we will be creating a gradient background later using CSS
              backgroundAlpha: 0,
              forceCanvas: true,
              // clearBeforeRender: true,
              // antialias: true,
              // powerPreference: 'low-power'
            });

            this.app.stage.filters = [new PIXI.filters.NoiseFilter(100, 100)]


            // Create colour palette
            //  console.log(999, this.gradientPalette)
            const colorPalette = new ColorPalette(this.gradientPalette);


            // import('@pixi/filter-kawase-blur').then(KawaseBlurFilter => {
            //   // let blur=  KawaseBlurFilter;
            //   // this.app.stage.filters = [new KawaseBlurFilter.KawaseBlurFilter(30, 10, true)];
            // });




            // // Create orbs


            let t = window.innerWidth < 1000 ? 3 : this.isFullBg ? (this.gradientPalette == 'loader' ? 7 : 4) : 3;
            for (let i = 0; i < t; i++) {
              this.graphics = new PIXI.Graphics();


              const orb = new Orb(colorPalette.randomColor(), this.graphics, this.isFullBg, this.gradientPalette);

              this.app.stage.addChild(orb.graphics);

              this.orbs.push(orb);
            }

            colorPalette.setColors(this.gradientPalette);
            this.orbs.forEach((orb) => {
              orb.fill = colorPalette.randomColor();
            });

            if (this.app) {



              if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {

                this.app.ticker.add(() => {
                  if (this.play) {
                    this.orbs.forEach((orb) => {
                      orb.update();
                      orb.render();
                    });
                  }

                });
              } else {
                this.app.ticker.add(() => {
                  if (this.play) {
                    this.orbs.forEach((orb) => {
                      orb.update();
                      orb.render();
                    });
                  }
                });
              }

              // Animate!
              this.playGradient.subscribe(play => {
                if (play) {
                  this.app.start();
                }
                else {
                  this.app.stop();
                }
              })

              setTimeout(() => {
                if (this.app) {


                  if (this.app.ticker) {
                    this.app.ticker.stop();
                    this.app.stop();
                  }


                  if (this.autoPlay && window.innerWidth > 800) {
                    this.play()
                  }
                }
              }, 500)
            }

          });
        }
      });

    }
  }

  play() {
    this.playGradient.next(true);

    if (this.app)
      this.app.ticker.start();


  }
  pause() {
    if (this.app) {
      this.playGradient.next(false);
      this.app.ticker.stop();
    }



  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.autoPlay) {
      this.play()
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.autoPlay || window.innerWidth < 800) {
      this.pause()
    }

  }

  destroy() {
    if (this.isBrowser) {
      if (this.app) {
        this.app.destroy();
      }
    }

  }

  ngOnDestroy() {
    // console.log('destroy gradient');
    this.destroy()
  }


}


// ColorPalette class
class ColorPalette {

  public hue = this.random(0, 360);;
  public complimentaryHue1 = this.hue + 30;
  public complimentaryHue2 = this.hue + 60;
  public saturation = 0;
  public lightness = 0;
  public saturation2 = 0;
  public lightness2 = 0;
  public baseColor = hsl(this.hue, this.saturation, this.lightness);
  public complimentaryColor1 = hsl(
    this.complimentaryHue1,
    this.saturation,
    this.lightness
  );
  public complimentaryColor2 = hsl(
    this.complimentaryHue2,
    this.saturation,
    this.lightness
  );
  public colorChoices = [
    this.baseColor,
    this.complimentaryColor1,
    this.complimentaryColor2
  ];;

  constructor(colorPalette) {

    this.setColors(colorPalette);
  }

  // return a random number within a range
  public random(min, max) {
    return Math.random() * (max - min) + min;
  }

  // map a number from 1 range to another
  public map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  setColors(colorPalette) {
    // pick a random hue somewhere between 000 and 360

    // console.log(123, themeColorPalette.purple)
    let palette =
      colorPalette == '3' || colorPalette == '4' ? themeColorPalette.purple :
        colorPalette == '1' || colorPalette == '2' ? themeColorPalette.blue :
          colorPalette == '5' || colorPalette == '6' ? themeColorPalette.yellow :
            colorPalette == '7' || colorPalette == '8' ? themeColorPalette.green :
              colorPalette == '9' || colorPalette == '11' ? themeColorPalette.pink :
                colorPalette == 'menu' ? themeColorPalette.menu :
                  colorPalette == 'loader' ? themeColorPalette.loader : [];


    //  console.log('palette=', colorPalette)

    this.hue = 257;// this.random(0, 360);
    this.complimentaryHue1 = 217;
    this.complimentaryHue2 = this.hue + 10;

    // define a fixed saturation and lightness

    this.saturation = 46;
    this.lightness = 57;

    this.saturation2 = 67;
    this.lightness2 = 56;

    // define a base color
    this.baseColor = hsl(this.hue, this.saturation, this.lightness);
    // define a complimentary color, 30 degress away from the base
    this.complimentaryColor1 = hsl(
      this.complimentaryHue1,
      this.saturation2,
      this.lightness2
    );
    // define a second complimentary color, 60 degrees away from the base
    this.complimentaryColor2 = hsl(
      this.complimentaryHue2,
      this.saturation,
      this.lightness
    );

    // store the color choices in an array so that a random one can be picked later
    this.colorChoices = palette;
  }

  randomColor() {
    //  console.log(this.colorChoices)
    // pick a random color
    return this.colorChoices[~~this.random(0, this.colorChoices.length)].replace(
      "#",
      "0x"
    );
  }

}



// // Orb class
class Orb {
  public bounds;
  public x = 0;
  public y = 0;
  public scale = 0;
  public fill;
  public radius;
  public xOff;
  public yOff;
  public inc;
  public graphics;
  public simplex = new SimplexNoise();
  public isFullBg: boolean = false;
  public colorPalette;

  // return a random number within a range
  public random(min, max) {
    return Math.random() * (max - min) + min;
  }

  // map a number from 1 range to another
  public map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  // Pixi takes hex colors as hexidecimal literals (0x rather than a string with '#')
  constructor(fill = 0x000000, graphics, isFullBg = false, colorPalette = '') {
    // bounds = the area an orb is "allowed" to move within
    this.bounds = this.setBounds();
    // initialise the orb's { x, y } values to a random point within it's bounds
    this.x = this.random(this.bounds["x"].min, this.bounds["x"].max);
    this.y = this.random(this.bounds["y"].min, this.bounds["y"].max);

    this.isFullBg = isFullBg;
    this.colorPalette = colorPalette;

    // how large the orb is vs it's original radius (this will modulate over time)
    this.scale = isFullBg ? (this.colorPalette != 'loader' ? 5 : 0.01) : 1;

    // what color is the orb?
    this.fill = fill;

    // the original radius of the orb, set relative to window height
    //this.radius = random(window.innerWidth / 12, window.innerHeight);
    this.radius = this.colorPalette != 'loader' ? this.random(300, 400) : this.random(100, 200);

    // starting points in "time" for the noise/self similar random values
    this.xOff = this.random(0, 100);
    this.yOff = this.random(0, 100);
    // how quickly the noise/self similar random values step through time
    this.inc = this.isFullBg ? this.colorPalette == 'loader' ? 0.0003 : 0.0009 : 0.002;

    // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
    this.graphics = graphics;
    this.graphics.alpha = isFullBg ? 0.45 : 0.7;

    // 250ms after the last window resize event, recalculate orb positions.
    // window.addEventListener(
    //   "resize",
    //   debounce(() => {
    //     this.bounds = this.setBounds();
    //   }, 250)
    // );
  }

  setBounds() {
    // how far from the { x, y } origin can each orb move
    /*const maxDist =
      window.innerWidth < 1000 ? window.innerWidth / 2 : window.innerWidth / 1;*/

    const maxDist = this.isFullBg ? 200 : 600;

    // the { x, y } origin for each orb (the bottom right of the screen)
    //const originX = window.innerWidth / 2;
    const originX = 300;
    const originY = 300;
    /*
    const originY =
      window.innerWidth < 1000 ? window.innerHeight/2 : window.innerHeight / 2;
    */

    // allow each orb to move x distance away from it's x / y origin
    return {
      x: {
        min: originX - maxDist / 2,
        max: originX + maxDist / 2
      },
      y: {
        min: originY - maxDist / 2,
        max: originY + maxDist / 2
      }
    };
  }

  update() {
    // self similar "psuedo-random" or noise values at a given point in "time"
    const xNoise = this.simplex.noise2D(this.xOff, this.xOff);
    const yNoise = this.simplex.noise2D(this.yOff, this.yOff);
    const scaleNoise = this.simplex.noise2D(this.xOff, this.yOff);

    // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
    this.x = this.map(xNoise, -1, 1, this.bounds["x"].min, this.bounds["x"].max);
    this.y = this.map(yNoise, -1, 1, this.bounds["y"].min, this.bounds["y"].max);
    // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
    this.scale = this.isFullBg ? (this.colorPalette != 'loader' ? this.map(scaleNoise, -1, 1, 1.2, 1) : this.map(scaleNoise, -1, 1, 0.5, 1)) : this.map(scaleNoise, -1, 1, 0.5, 1);

    // step through "time"
    this.xOff += this.inc;
    this.yOff += this.inc;
  }

  render() {
    //console.log('render')
    // update the PIXI.Graphics position and scale values
    this.graphics.x = this.x;
    this.graphics.y = this.y;
    this.graphics.scale.set(this.scale);

    // clear anything currently drawn to graphics
    this.graphics.clear();

    // tell graphics to fill any shapes drawn after this with the orb's fill color
    this.graphics.beginFill(this.fill);
    // draw a circle at { 0, 0 } with it's size set by this.radius
    this.graphics.drawCircle(this.radius / 2, this.radius / 2, this.radius);
    // let graphics know we won't be filling in any more shapes
    this.graphics.endFill();
  }


}
