import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloaderService } from './providers/preloader/preloader.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicPageModule),
    resolve: { data: PreloaderService }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
