import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { LanguageService } from '../../localization/language.service';

@Injectable({
    providedIn: 'root'
})
export class FocusAreasCollection {
    public key = 'focus-areas';
    public keyId = 'focus-area-view';

    public pageData = null;

    constructor(private api: ApiService, private language: LanguageService) {

    }


    public get() {
        this.language.valueChanges.subscribe(lang => {
            this.pageData = this.api.get(this.key + '?_sort=order', true);
        })

        return this.pageData;
    }
    public getById(id) {
        this.pageData = this.api.get(this.keyId + '?id=' + id, true);
        return this.pageData
    }

}

