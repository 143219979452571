import { NgModule } from '@angular/core';
import { HomePageCollection } from './models/homepage.collection';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  providers: [
    HomePageCollection,
  ],
  imports: [
    HttpClientModule,
  ],
})
export class DataModule { }
