import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderCheckService {


  public isLoaded: ReplaySubject<boolean> = new ReplaySubject();
  public showPage: ReplaySubject<boolean> = new ReplaySubject();
  public destroyLoader: ReplaySubject<boolean> = new ReplaySubject();

  constructor() { }

}
