import { NgModule } from '@angular/core';
import { PreloaderComponent } from './preloader.component';
import { GradientBackgroundModule } from '../../directives/gradient-background/gradient-background.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({

  imports: [
    GradientBackgroundModule,
    TranslateModule
  ],
  exports: [PreloaderComponent,],
  declarations: [PreloaderComponent],
})
export class PreloaderModule { }
