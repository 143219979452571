import { NgModule } from '@angular/core';
import { MetaService } from './meta.service';



@NgModule({
  providers: [
    MetaService
  ]

})
export class MetaModule { }
