import { NgModule } from '@angular/core';
import { GradientBackgroundDirective } from './gradient-background.directive';


@NgModule({
    declarations: [GradientBackgroundDirective],
    imports: [
    ],
    exports: [GradientBackgroundDirective]
})
export class GradientBackgroundModule { }
