import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { LanguageService } from '../../localization/language.service';

@Injectable({
    providedIn: 'root'
})
export class OngoingWorkCollection {
    public key = 'home-view';
    public pageData = null;

    constructor(private api: ApiService, private language: LanguageService) { }


    public get() {
        this.language.valueChanges.subscribe(lang => {
            this.pageData = this.api.get(this.key);
        })
        return this.pageData;
    }
}

