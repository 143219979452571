import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	public defaultLanguage = 'en-US';

	public valueChanges: ReplaySubject<string> = new ReplaySubject();
	public showTranslationChanges: ReplaySubject<boolean> = new ReplaySubject();

	constructor(
		private translate: TranslateService,
		@Inject(PLATFORM_ID) private platformId: Object,
		private location: Location
	) {
		this.language = this.language;

	}

	public get language() {
		let _language: string;

		// if (isPlatformBrowser(this.platformId)) {
		// 	_language = localStorage.getItem('language');
		// 	if (!_language) {
		// 		_language = navigator.language.indexOf('ar') >= 0 ? 'ar' : 'en-US'
		// 	}
		// }
		// else {
		let split_url = this.location.path().replace(/\/\s*$/, '').split('/');
		split_url.shift();

		if (split_url[0] == 'ar') {
			_language = 'ar';
		}
		else if (split_url[0] == '404') {
			if (this.getHTMLDirection() == 'rtl') {
				_language = 'ar';
			}
			else {
				_language = 'en-US';
			}
		}
		else {
			_language = 'en-US';
		}
		//}
		if (!_language || _language === 'null' || _language === 'false') {
			_language = this.defaultLanguage;
		}

		return _language;
	}

	public get direction() {
		return this.language === 'ar' ? 'rtl' : 'ltr';
	}

	public set language(value: string) {
		this.setLanguage(value)
	}

	public setLanguage(value: string, emitEvent = true) {
		if (!value) {
			return;
		}
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem('language', value);
			if (value === 'ar') {
				document.querySelector('html').setAttribute('dir', 'rtl');
			} else {
				document.querySelector('html').setAttribute('dir', 'ltr');
			}
		}
		this.translate.setDefaultLang(this.language);
		this.translate.use(value)

		if (emitEvent) {
			this.valueChanges.next(this.language);

		}
	}

	public getHTMLDirection() {
		if (isPlatformBrowser(this.platformId)) {
			return document.querySelector('html').getAttribute('dir');
		}
	}




}
