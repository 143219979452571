import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

interface MetaOptions {
  title: string;
  description: string;
  image?: string;
  keywords?: string;
  type?: string;
  price?: number;
}

export enum SchemaClasses {
  website = 'structured-data-website',
  product = 'structured-data-product',
  organization = 'structured-data-organization'
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  static scriptType = 'application/ld+json';
  public websiteSchema = (url?: string, name?: string, type?: string) => {
    return {
      '@context': 'https://schema.org',
      '@type': type || 'Website',
      url: url || 'https://apwebsite.xyz',
      name: name || 'Alwaleed Philanthropies',
      sameAs: [
        'https://www.linkedin.com/company/alwaleed-philanthropies',
        'https://twitter.com/alwaleed_philan',
        'https://www.instagram.com/alwaleed_philan/',
        'https://www.youtube.com/channel/UCcbnorgWmKB5sM1himuZlvQ',
        'https://www.facebook.com/AlwaleedPhilanthropies/',
      ],
    };
  };

  public organizationSchema = (name?: string) => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://apwebsite.xyz',
    name: name || 'Alwaleed Philanthropies',
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'general@apwebsite.xyz',
      contactType: 'contact us',
    },
  });

  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private dom
  ) {}

  addMetaTags(options: MetaOptions) {
    this.title.setTitle(options.title);
    // GENERAL
    this.meta.updateTag({ name: 'title', content: options.title });
    this.meta.updateTag({ name: 'description', content: options.description });
    this.meta.updateTag({ name: 'image', content: options.image });
    this.meta.updateTag({ name: 'keywords', content: options.keywords });

    // OPEN GRAPH
    this.meta.updateTag({ property: 'og:title', content: options.title });
    this.meta.updateTag({
      property: 'og:description',
      content: options.description,
    });
    this.meta.updateTag({ property: 'og:image', content: options.image });
    this.meta.updateTag({ property: 'og:url', content: environment.hostUrl });
    options.type
      ? this.meta.updateTag({ property: 'og:type', content: options.type })
      : this.meta.updateTag({ property: 'og:type', content: 'website' });
    if (options.price) {
      this.meta.updateTag({
        property: 'product:price.amount',
        content: options.price.toString(),
      });
      this.meta.updateTag({
        property: 'product:price.currency',
        content: 'SAR',
      });
    }
    // TWITTER
    this.meta.updateTag({ property: 'twitter:title', content: options.title });
    this.meta.updateTag({ property: 'twitter:image', content: options.image });
    this.meta.updateTag({
      property: 'twitter:description',
      content: options.description,
    });

    this.createAlternateLink();

    //   this.updateCanonicalURL();
  }

  updateCanonicalURL() {
    // let oldlink: HTMLLinkElement = this.dom.querySelector(
    //   'link[rel=canonical]'
    // );
    // if (oldlink) {
    //   oldlink.remove();
    // }
    // let link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', this.dom.URL);
  }
  addCanonicalToPage() {
    let oldlink: HTMLLinkElement = this.dom.querySelector(
      'link[rel=canonical]'
    );
    if (oldlink) {
      oldlink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  createAlternateLink() {
    let oldlinks: HTMLLinkElement[] = this.dom.querySelectorAll(
      'link[rel=alternate]'
    );
    oldlinks.forEach((oldlink) => {
      oldlink.remove();
    });

    // let url = lang == 'ar' ? this.dom.URL.replace('/ar', '/en') : this.dom.URL.replace('/en', '/ar')
    // // console.log(this.dom.URL)
    // const _link = this.dom.createElement('link');
    // _link.setAttribute('rel', 'alternate');
    // _link.setAttribute('hreflang', lang == 'ar' ? 'en' : 'ar');
    // _link.setAttribute('href', url);
    // this.dom.head.appendChild(_link);

    const _linkDefault = this.dom.createElement('link');
    _linkDefault.setAttribute('rel', 'alternate');
    _linkDefault.setAttribute('hreflang', 'x-default');
    _linkDefault.setAttribute('href', this.dom.URL);
    this.dom.head.appendChild(_linkDefault);
  }

  removeStructuredData(): void {
    const els = [];
    [
      SchemaClasses.website,
      SchemaClasses.organization,
      SchemaClasses.product,
    ].forEach((c) => {
      els.push(...Array.from(this.dom.head.getElementsByClassName(c)));
    });
    els.forEach((el) => this.dom.head.removeChild(el));
  }

  insertSchema(
    schema: Record<string, any>,
    className = SchemaClasses.website
  ): void {
    let script;
    let shouldAppend = false;
    if (this.dom.head.getElementsByClassName(className).length) {
      script = this.dom.head.getElementsByClassName(className)[0];
    } else {
      script = this.dom.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = MetaService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.dom.head.appendChild(script);
    }
  }
}
