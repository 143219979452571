import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { PreloaderCheckService } from '../../providers/preloader/preloader-check.service';
import gsap from 'gsap';
import { LanguageService } from '../../providers/localization/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ap-preloader',
  templateUrl: 'preloader.component.html',
  styleUrls: ['preloader.component.scss'],
})
export class PreloaderComponent implements OnInit, OnDestroy {
  @Output() destroyLoader = new EventEmitter<boolean>();

  public siteLanguage = this.language.language;
  public languageSubscription: Subscription;
  isLoaded = false;
  isActive = false;
  loader = null;


  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }



  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public preloadCheck: PreloaderCheckService,
    private language: LanguageService
  ) {
    this.languageSubscription = this.language.valueChanges.subscribe(lang => {
      this.siteLanguage = lang;
    })
  }


  ngOnInit() {

    let completed = false;

    if (this.isBrowser) {


      gsap.timeline(
        {
          onComplete: () => {
            completed = true;

          }
        }
      )
        .to('.preloader-container > div.preloader-message1', { alpha: 1, y: 0, duration: 1 })
        .to('.preloader-container > div.preloader-message1', { alpha: 0, duration: 1, delay: 1, })
        .fromTo('.preloader-container > div.preloader-message2',
          { alpha: 0, },
          { alpha: 1, duration: 1 }
        )




      let parent = this;
      this.loader = gsap.timeline(
        {
          repeat: this.isLoaded ? 0 : -1,
          onComplete: () => {
            //   gsap.to(this._targets, { opacity: 0 });

          }
        }
      )

        .fromTo('.dot', { opacity: 0, }, {
          opacity: 1, duration: 1,
          stagger: {
            each: 0.5
          },
          onComplete: () => {
            completed && this.preloadCheck.isLoaded.subscribe(isLoaded => {
              this.isLoaded = isLoaded;

              if (isLoaded) {
                this.loader.pause();
                this.isLoaded = true;

                gsap.timeline(
                  {

                    onComplete: () => {
                      //    this.preloadCheck.showPage.next(true);
                      // setTimeout(() => {


                      // }, 1000)
                      this.destroyLoader.emit(true);
                      gsap.timeline({
                        onComplete: () => {
                          // this.preloadCheck.destroyLoader.next(true);
                          gsap.timeline().to('.preloader', { opacity: 0, duration: 0.5 }).to('.preloader', { display: 'none', duration: 0 });

                        }
                      })
                        .to('.preloader-message', { opacity: 0, duration: 0.5 })
                        .to('.preloader .circle',
                          {
                            width: '50px',
                            height: '50px',
                            left: 'calc(50vw - 25px)',
                            y: '50%',
                            duration: 0.8
                          }
                        )
                    }
                  }
                )
                  .to('.dot',
                    {
                      opacity: 1, duration: 2,

                    })
              }
            })
          }
        })
    }
    else {
      this.destroyLoader.emit(true);
      // this.preloadCheck.isLoaded.subscribe(isLoaded => {
      //   if (isLoaded) {
      //     setTimeout(() => {
      //       this.destroyLoader.emit(true);
      //     }, 12000)

      //   }
      // });
    }


  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }
}
