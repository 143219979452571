import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { LanguageService } from './providers/localization/language.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { PreloaderCheckService } from './providers/preloader/preloader-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isLoaded: ReplaySubject<boolean> = new ReplaySubject();
  public showPreloader: boolean = this.isBrowser ? true : false;
  public showContent = this.isBrowser ? false : true;


  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone,
    private metaTagService: Meta,
    public language: LanguageService,
    public route: Router,
    public location: Location,
    public preloadCheck: PreloaderCheckService
  ) {

    let split_url = this.location.path().replace(/\/\s*$/, '').split('/');
    split_url.shift();

    if (split_url[0] == 'ar') {
      this.metaTagService.addTags([
        { name: 'title', content: 'التزامنا للإنسانية أجمع' },
        { property: 'og:title', content: 'التزامنا للإنسانية أجمع' },

        { name: 'description', content: 'نبادر وندعم ونتعاون على مشاريع وحملات خيرية في جميع أنحاء العالم لنفيد الإنسانية بكل أجناسها وأعراقها وأداينها.' },
        { property: 'og:description', content: 'نبادر وندعم ونتعاون على مشاريع وحملات خيرية في جميع أنحاء العالم لنفيد الإنسانية بكل أجناسها وأعراقها وأداينها.' },

        { name: 'image', content: 'https://storage.googleapis.com/ap-site-bucket/large_what_we_do_2d5bfc6d0f/large_what_we_do_2d5bfc6d0f.jpg' },
        { property: 'og:image', content: 'https://storage.googleapis.com/ap-site-bucket/large_what_we_do_2d5bfc6d0f/large_what_we_do_2d5bfc6d0f.jpg' },
      ])
    }
    else {
      // this.language.language = 'ar';
      // this.language.setLanguage('ar');
      this.metaTagService.addTags([
        { name: 'title', content: 'Commitment to All Humanity' },
        { property: 'og:title', content: 'Commitment to All Humanity' },

        { name: 'description', content: 'We initiate, support & collaborate on philanthropic projects & campaigns around the world, regardless of gender, race or religion.' },
        { property: 'og:description', content: 'We initiate, support & collaborate on philanthropic projects & campaigns around the world, regardless of gender, race or religion.' },

        { name: 'image', content: 'https://storage.googleapis.com/ap-site-bucket/large_what_we_do_2d5bfc6d0f/large_what_we_do_2d5bfc6d0f.jpg' },
        { property: 'og:image', content: 'https://storage.googleapis.com/ap-site-bucket/large_what_we_do_2d5bfc6d0f/large_what_we_do_2d5bfc6d0f.jpg' },
      ])
    }
  }



  // destroyLoader(ev) {
  //   if (ev) {
  //     this.ngZone.run(() => {
  //       this.showContent = true;
  //       setTimeout(() => {
  //         this.showPreloader = false;
  //       }, 1000)

  //     });
  //   }

  // }

  ngOnInit(): void {
  }






}
