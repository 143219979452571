import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../localization/language.service';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiService {

    private apiUrl = environment.localesDirectory.apiUrl;
    private siteLanguage = this.language.language;
    public translatedId = 0;

    constructor(
        private http: HttpClient,
        public language: LanguageService,
        private router: Router
    ) {


    }

    reset() {
        //     console.log('reset');
        this.translatedId = 0;
        this.language.showTranslationChanges.next(true);
    }

    public getTranslatedId() {
        return this.translatedId;
    }


    get(api: string, appendLocale: boolean = false) {

        let locale = appendLocale ? '&_locale=' + this.siteLanguage : '?_locale=' + this.siteLanguage;

        this.language.valueChanges.subscribe(lang => {
            if (lang == 'en-US') {
                locale = appendLocale ? '&_locale=en' : '?_locale=en';
            }
            else {
                locale = appendLocale ? '&_locale=' + lang : '?_locale=' + lang;
            }
        })


        const url = encodeURI(this.apiUrl + `/` + api + locale);


        return this.http.get(url);


    }


    getById(api: string, apiName?: string, appendLocale: boolean = false) {
        let locale = appendLocale ? '&_locale=' + this.siteLanguage : '?_locale=' + this.siteLanguage;
        this.language.valueChanges.subscribe(lang => {
            if (lang == 'en-US') {
                locale = appendLocale ? '&_locale=en' : '?_locale=en';
            }
            else {
                locale = appendLocale ? '&_locale=' + lang : '?_locale=' + lang;
            }
        })

        const url = encodeURI(this.apiUrl + `/` + api + locale);
        return this.http.get(url).pipe(
            tap((res: any) => {

                this.translatedId = 0;



            })
        )
    }

    getByURL(api: string, url, appendLocale: boolean = false) {

        let locale = appendLocale ? '&_locale=' + this.siteLanguage : '?_locale=' + this.siteLanguage;

        this.language.valueChanges.subscribe(lang => {
            if (lang == 'en-US') {
                locale = appendLocale ? '&_locale=en' : '?_locale=en';
            }
            else {
                locale = appendLocale ? '&_locale=' + lang : '?_locale=' + lang;
            }
        })


        const link = encodeURI(this.apiUrl + `/` + api + '?url=' + url + locale);

        // console.log(link)
        return this.http.get(link).pipe(
            map(data => data[0]),
            tap((res: any) => {
                //  console.log('data url =', res)

                if (!res) {
                    this.router.navigate(['/404']);
                }
                else {


                    if (api == 'news-articles' || api == 'projects' || api == 'campaigns' || api == 'thought-leaderships') {
                        if (res && res.localizations && res.localizations.length > 0 && res.localizations[0].id) {
                            this.translatedId = res.localizations[0].id;
                            this.language.showTranslationChanges.next(true);
                        }
                        else {
                            this.translatedId = 0;
                            this.language.showTranslationChanges.next(false);

                        }
                    }
                }


            })
        );


    }




    subscribeToNewsletter(data: { email: string; token: string }) {
        //    const link = 'http://localhost:1337/newsletter/subscribe';
        const link = this.apiUrl + `/newsletter/subscribe`;
        return this.http.post(link, data)



    }


    search(q: string) {
        if (!q || q === '*') {
            q = '';
        } else {
            //  q = q.toLowerCase();
            q = q;
        }

        if (q.replace(/\s/g, '').length != 0) {

            //  console.log('not emptyyy', q.replace(/\s/g, ''))
            const url = encodeURI(this.apiUrl + '/' + environment.filterAPIKey + '?_q=' + q);
            //  console.log(url)
            //  let pageData = await axios.get(url, environment.postmanConfig);
            return this.http.get(url);

        }
        return null




    }



}