import { Injectable, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { LanguageService } from '../../localization/language.service';
import { MetaService } from '../../meta/meta.service';

@Injectable({
    providedIn: 'root'
})
export class HomePageCollection {
    public key = 'homepage';

    public pageData = null;

    constructor(
        private api: ApiService,
        private language: LanguageService,
        private metaService: MetaService) {
    }



    public get() {
        // console.log('home get')
        this.language.valueChanges.subscribe(lang => {
            this.pageData = this.api.get(this.key);
        })
        return this.pageData;
    }

    public setMetaTags() {
        this.pageData.subscribe(data => {
            this.metaService.addMetaTags({
              title:
                data.SEO && data.SEO.PageTitle
                  ? data.SEO.PageTitle
                  : 'Alwaleed Philanthropies',
              description:
                data.SEO && data.SEO.Description ? data.SEO.Description : null,
              image:
                data.SEO && data.SEO.Image
                  ? data.SEO.Image.url
                  : 'https://apwebsite.xyz/assets/media/logo-green.svg',
              type: 'website',
              keywords: data.SEO && data.SEO.MetaTags ? data.SEO.MetaTags : '',
            });
            this.metaService.removeStructuredData();
            this.metaService.insertSchema(this.metaService.organizationSchema());
            this.metaService.insertSchema(this.metaService.websiteSchema());
        })
    }

}

