<div class="preloader" [class.ar]="siteLanguage=='ar'">
    <div class="preloader-container">
        <div class="preloader-message preloader-message1">
            <h1>{{'PRELOADER.COMMITMENT' | translate}}</h1>
            <h1>{{'PRELOADER.WITHOUT' | translate}}</h1>
            <h1>{{'PRELOADER.BOUNDARIES' | translate}}</h1>
        </div>
        <div class="preloader-message preloader-message2">
            <h1>{{'PRELOADER.COMMITMENT' | translate}}</h1>
            <h1>{{'PRELOADER.TO_ALL' | translate}}</h1>
            <h1>{{'PRELOADER.HUMANKIND' | translate}} <span class="dots"><span class="dot">.</span><span
                        class="dot">.</span><span class="dot">.</span></span>
            </h1>
        </div>

    </div>

    <div class="circle " [class.active]="isActive" appGradientBackground [autoPlay]="true" [isFullBg]="true"
        [gradientHolder]="true" gradientPalette="loader">
    </div>

</div>