import { NgModule } from '@angular/core';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { MarkdownModule } from 'ngx-markdown';
import { DataModule } from './data/data.module';
import { LanguageModule } from './localization/language.module';
//import { MarkdownModule } from 'ngx-markdown'
import { environment } from '../../environments/environment';
import { ApiService } from './api/api.service';
import { PreloaderCheckService } from './preloader/preloader-check.service';
import { PreloaderService } from './preloader/preloader.service';
import { MetaModule } from './meta/meta.module';

@NgModule({

  imports: [
    DataModule,
    //   AnalyticsModule,
    MarkdownModule.forRoot(),
    RecaptchaV3Module,

    LanguageModule,
    MetaModule
    //  MarkdownModule.forRoot(),
  ],
  providers: [
    ApiService,
    PreloaderCheckService, PreloaderService,

    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
  ],
})
export class ProvidersModule { }
