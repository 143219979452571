import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Resolve } from '@angular/router';
import { AreasOfImpactSinglePage } from '../data/models/areas-of-impact-page.single';
import { FocusAreasCollection } from '../data/models/focus-areas.collection';
import { HomePageCollection } from '../data/models/homepage.collection';
import { HowWeThinkSinglePage } from '../data/models/how-we-think-page.single';
import { OngoingWorkCollection } from '../data/models/ongoing-work.collection';
import { whatWeDoCollection } from '../data/models/what-we-do.collection';
import { WhoWeAreCollection } from '../data/models/who-we-are.collection';
import { PreloaderCheckService } from './preloader-check.service';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService implements Resolve<Promise<any>> {

  mediaFiles = [
    {
      url: "/assets/media/ap-video.mp4",
      type: 'audio'
    }
  ];


  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public preloadCheck: PreloaderCheckService,
    public homePageData: HomePageCollection,
    public onGoingProjectsData: OngoingWorkCollection,
    public howWeThinkData: HowWeThinkSinglePage,
    public whoWeAreData: WhoWeAreCollection,
    public whhatWeDoData: whatWeDoCollection,
    public areaOfImpactsData: AreasOfImpactSinglePage,
    public focusAreaData: FocusAreasCollection,
  ) {
  }


  //  public server = new Promise((res, req) => res('server'));

  resolve = async (): Promise<string[]> => this.isBrowser ? Promise.all(
    (

      [



        new Promise(async (res, req) => {
          //   console.log('called')
          await this.preloadAudio(this.mediaFiles[0].url, this.mediaFiles[0].type);
          let homeData = await this.homePageData.get();
          let onGoingProjectsData = await this.onGoingProjectsData.get();
          let howWeThinkData = await this.howWeThinkData.get();
          let whoWeAreData = await this.whoWeAreData.get();
          let whhatWeDoData = await this.whhatWeDoData.get();
          let areaOfImpactsData = await this.areaOfImpactsData.get();
          let focusAreaData = await this.focusAreaData.get();


          this.preloadCheck.isLoaded.next(true);


          return res('true')
          // return this.homePageData.get().pipe(first()).toPromise().then(res2 => {
          //   console.log('loaded home')
          //   this.preloadCheck.isLoaded.next(true);
          //   return res('true')
          // })
        })

      ]
      //   [this.homePageData.get().pipe().toPromise().then(res=>)]

      // this.mediaFiles.map(media => {
      //     return this.preloadAudio(media.url, media.type)
      // }) 

    )

  ) : this.serverLoad();





  async serverLoad() {
    // console.log('called');
    let homeData = await this.homePageData.get();
    let onGoingProjectsData = await this.onGoingProjectsData.get();
    let howWeThinkData = await this.howWeThinkData.get();
    let whoWeAreData = await this.whoWeAreData.get();
    let whhatWeDoData = await this.whhatWeDoData.get();
    let areaOfImpactsData = await this.areaOfImpactsData.get();
    let focusAreaData = await this.focusAreaData.get();

    this.preloadCheck.isLoaded.next(true);
    return null;
  }
  preloadAudio = async (url: string, type: string): Promise<string> => {
    var video = document.createElement('video');
    video.src = url;
    video.autoplay = true;
    // console.log(video)

    // video.addEventListener('loadstart', () => {
    //   console.log("Video loadedmetadata!");
    // });
    return new Promise((res, req) => video.addEventListener('loadeddata', () => {

      // console.log('loaded1')
      this.preloadCheck.isLoaded.next(true);
      return res(url)
    }, false))


  }
}
