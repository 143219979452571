import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ProvidersModule } from './providers/providers.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderModule } from './components/preloader/preloader.module';
import { TransferHttpCacheModule } from '@nguniversal/common'; // HERE
@NgModule({

  declarations: [
    AppComponent
  ],
  imports: [

    ProvidersModule,

    PreloaderModule,
    BrowserModule.withServerTransition({ appId: 'alwaleed-philanthropies' }),
    TransferHttpCacheModule,
    AppRoutingModule,




  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
